import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header-2';
import SEO from "../components/seo";
import LogoImage from '../images/logo.png';
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const BlogPage = (props) => {
  const POST_PER_PAGE = 100;
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  //
  const ALL_POSTS = props.pageContext.posts;
  const allPostLength = ALL_POSTS.length;
  // get category name
  let categories = new Set();
  ALL_POSTS.forEach(p => {
    p.categories.nodes.forEach(c => categories.add(c.name));
  });
  categories = Array.from(categories);
  // alphabet
  categories.sort();
  //
  const seo = {};
  //
  const isDisabledPrevious = () => {
    return currentIndex === 0;
  }

  const isDisabledNext = () => {
    return (currentIndex + POST_PER_PAGE) >= allPostLength;
  }

  const isPaging = () => {
    return selectedCategory === '';
  }

  const jump = (direction) => {
    if (direction) {
      setCurrentIndex(currentIndex + POST_PER_PAGE);
    } else {
      setCurrentIndex(currentIndex - POST_PER_PAGE);
    }
  }
  return (
    <Layout>
      <SEO title="blog" canonical="/blog/" seo={seo} />
      <Header home={false} menu='blog'/>
      <main>
        <section class="blog-banner-title py-5">
          <div class="container">
              <h2 class="page-title text-white">Blogs</h2>
          </div>
        </section>
        <section class="py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <p className="d-inline fs-5 ">
                    <a className={"text-decoration-none " + (!selectedCategory ? "fw-bold text-decoration-underline" : "")} href="#" onClick={(e) => {e.preventDefault(); setSelectedCategory(''); }}>All Posts</a>
                  </p>

                  {
                    categories.map((c, index) => (
                      <p className="ms-4 d-inline fs-5 ">
                        <a className={"text-decoration-none " + (selectedCategory === c ? "fw-bold text-decoration-underline" : "")} href="#" onClick={(e) => {e.preventDefault(); setSelectedCategory(c); }}>{ c }</a>
                      </p>
                    ))
                  }

                </div>
              </div>

              {
              <div className="py-5">
                <div className="container">
                  <div className="row ps-0">
                    {
                      ALL_POSTS.map((node, index) => 
                      ((isPaging() && ((index >= currentIndex) && (index < (currentIndex + POST_PER_PAGE)))) || node.categories.nodes.some(c => c.name === selectedCategory)) ? 
                        <div className="col-md-4 col-sm-12 ps-0">
                          <div className="card ms-0 mb-5" style={{'border': 'none'}}>
                            <div className="text-center">
                              { node.featuredImage?.node.localFile?.childImageSharp ?
                                <GatsbyImage
                                  image={
                                  node.featuredImage?.node.localFile?.childImageSharp?.gatsbyImageData
                                  }
                                  alt="blog"
                                />
                                : node.featuredImage?.node.mediaItemUrl ? <img src={node.featuredImage?.node.mediaItemUrl} height="300" width="100%"/>
                                : <img src={LogoImage} height="230"/>
                              }
                            </div>
                            <div className="card-body">
                              
                                <Link to={"/blog/" + node.slug} style={{ textDecoration: 'none' }}>
                                  <h5 className="card-title">{node.title}</h5>
                                </Link>
                              <p>{node.seo?.metaDesc || node.seo?.focuskw || node.title}</p>
                            </div>
                          </div>
                        </div>
                      : null
                      )
                    }
                  </div>
                  <div className="row">
                    <div className={isPaging()? '' : 'd-none'}>
                      <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-center">
                          <li className={ isDisabledPrevious() ? 'page-item disabled' : 'page-item'}>
                            <a className="page-link" href="#" onClick={evt => jump(false)} aria-disabled="true">Previous</a>
                          </li>
                          <li className={ isDisabledNext() ? 'page-item disabled' : 'page-item'}>
                            <a className="page-link" href="#"  onClick={evt => jump(true)}>Next</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                </div>
                </div>
              </div>
              }
            </div>
        </section>
      </main>
    </Layout>
  )
}

// export const query = graphql`
//   {
//     allWpPost {
//       nodes {
//         excerpt
//         content
//         id
//         uri
//         title
//         slug
//         date(fromNow: true)
//         categories {
//           nodes {
//             name
//           }
//       }
//       featuredImage {
//           node {
//             localFile {
//               childImageSharp {
//                 gatsbyImageData(width: 1920, placeholder: TRACED_SVG, height: 100)
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

export default BlogPage;
